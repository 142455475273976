:global(#nav-client-logo) {
  height: 75%;
  margin-top: 7px;
  margin-left: 7px;
  float: left;
  :global(#mxLogo) {
    max-height: 100%;
  }
}
.logoLeft {
  display: flex;
  position: absolute;
  width: 30vw;
  & > div {
    position: relative !important;
  }
}
.navRollUp {
  height: 0;
  animation: navRollUp 0.5s linear 3.5s forwards;
}
.noRollUp {
  height: calc(30px + 1vw);
}
.navHeader {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 12px is width of scrollbar set in index.scss */
  xxxoverflow: hidden; /*  Need visible for Srx autocomplete  */
  background: #fff;
  .clientLogoALink {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 7px;
    padding-left: 20px;
    img {
      height: 1.8vw;
    }
  }
  .flagsLangDiv {
    float: right;
    width: fit-content;
    height: 100%;
    padding-right: 4.3vw;
    display: flex;
    .flagsDiv {
      display: flex;
      align-items: center;
      padding-right: 2.25vw;
      a {
        margin-right: 0.75vw;
        border-radius: unset;
        img {
          width: 3vw;
          height: 1.8vw;
          border-radius: unset;
        }
      }
    }
    .langDiv {
      display: flex;
      align-items: center;
      font-size: calc(5px + 0.75vw);
      color: #828282;
      .langDivText {
        touch-action: inherit;
      }
      .langSelect {
        outline: none;
        font-size: calc(5px + 0.6vw);
        border: 1px solid transparent;
      }
    }
  }
  nav {
    float: right;
    height: 100%;
    padding-top: 0.4vw;
    font-size: 100%;
    a {
      margin: 0;
      min-width: 3.75vw;
      height: 100%;
      // padding: 0 1.13vw;
      padding: 0 0.5vw;
      text-align: center;
      vertical-align: middle;
      // font-size: calc(5px + 1vw);
      font-size: calc(5px + 0.8vw);
      text-decoration: none;
      color: rgba(10, 60, 130, 1);
      background: rgba(255, 255, 255, 0);
      will-change: background-color;
      cursor: pointer;
      &:hover,
      &:active {
        color: #999;
      }
      svg {
        width: 1.25vw;
      }
    }
  }
  .btnCms {
    color: green;
    padding: 0.25vw 1vw 0;
    border: 1px solid #ccc;
    xxxborder-radius: 5px;
    background: palegreen;
  }
}
.oFlowHidden {
  overflow: hidden;
}
.active {
  background: rgba(0, 0, 0, 0.4) !important;
}
@keyframes navRollUp {
  0% {
    height: 0;
  }
  100% {
    height: calc(30px + 1vw);
  }
}
@media (max-width: 599px) {
  .flagsDiv,
  .langDivText {
    display: none !important;
  }
}
