import { h, FunctionalComponent } from 'preact';
import style from './emailalreadyregistered.scss';
import { useEffect, useState } from 'preact/hooks';
import { get } from '../../../utils/ajax';

interface EmailAlreadyRegisteredProps {
  error: string;
  email: string;
}

export const EmailAlreadyRegistered: FunctionalComponent<EmailAlreadyRegisteredProps> = ({ error, email }) => {
  const [supportEmail, setSupportEmail] = useState('');

  /* *********************************************************************** */
  useEffect(() => {
    get('/getScratchpadValue?key=support_email').then((eml) => {
      setSupportEmail(eml.value);
    });
  });
  /* *********************************************************************** */

  return (
    <div class={style.emailAlreadyRegisteredDiv}>
      {/* <div class={style.greeting}>
        Hello <span class={style.fgBlue}>{`${nname}`}</span>,
      </div> */}
      <div class={style.confirmation}>
        The email address <span class={style.fgBlue}>{`${email}`}</span> is already associated with another account.
        {/* Thank you for registering <span class={style.fgBlue}>{`${email}`}</span> for a Flottec account. */}
      </div>
      <div class={style.message}>
        If this is your account and you want to reset the account, please contact the office. Otherwise, please register using a different email
        address.
      </div>
      <div class={style.message}>
        If urgent, please{' '}
        <a
          href={`mailto:${supportEmail}?subject=WUR:%20Email%20address%20already%20registered&body=The%20Flottec%20server%20reports%20that%20my%20email%20address%20[%20${email}%20]%20is%20already%20associated%20with%20an%20existing%20account%20on%20the%20Flottec%20system.%0D%0A%0D%0ATo%20discuss,%20please%20telephone%20me%20at:%0D%0A%0D%0A[%20(area%20code)%20telephone%20number%20].%0D%0A%0D%0AThank%20you,%0D%0A%0D%0A[%20name%20]%0D%0A[%20company%20]`}
        >
          email
        </a>{' '}
        or telephone <span class={style.fgBlue}>1.713.425.7055</span>
      </div>
      <div class={style.closing}>
        <div class={style.ty}>Thank you,</div>
        <div>Flottec Network Services,</div>
        <div>Main Corporate Office</div>
      </div>
    </div>
  );
};
