import { h, FunctionalComponent, ComponentChildren, JSX } from 'preact';
import style from './activelink.scss';
import { Link, useRoute } from 'wouter-preact';

interface ActiveLinkProps extends JSX.HTMLAttributes {
  href: string;
  children: ComponentChildren;
  exact?: boolean;
  activeClass?: string;
}

export const ActiveLink: FunctionalComponent<ActiveLinkProps> = ({ href, children, exact = false, activeClass = style.active, ...props }) => {
  const compare = exact ? href : `${href}/:sub?`;
  const [isActive] = useRoute(compare);
  return (
    <Link href={href}>
      <a {...props} class={isActive ? activeClass : ''}>
        {children}
      </a>
    </Link>
  );
};
