.galleriaDiv {
  @mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  // Styling

  section {
    background: #f4f4f4;
    padding: 50px 0;
  }

  .container {
    max-width: 1044px;
    margin: 20px auto 0;
    padding: 0 20px;
  }

  .carousel {
    display: block;
    text-align: left;
    position: relative;
    margin-bottom: 22px;

    > input {
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      width: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;

      &:nth-of-type(18):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1700%;
      }
      &:nth-of-type(17):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1600%;
      }
      &:nth-of-type(16):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1500%;
      }
      &:nth-of-type(15):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1400%;
      }
      &:nth-of-type(14):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1300%;
      }
      &:nth-of-type(13):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1200%;
      }
      &:nth-of-type(12):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1100%;
      }
      &:nth-of-type(11):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -1000%;
      }
      &:nth-of-type(10):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -900%;
      }
      &:nth-of-type(9):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -800%;
      }
      &:nth-of-type(8):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -700%;
      }
      &:nth-of-type(7):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -600%;
      }
      &:nth-of-type(6):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -500%;
      }
      &:nth-of-type(5):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -400%;
      }
      &:nth-of-type(4):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -300%;
      }
      &:nth-of-type(3):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -200%;
      }
      &:nth-of-type(2):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: -100%;
      }
      &:nth-of-type(1):checked ~ .carousel__slides .carousel__slide:first-of-type {
        margin-left: 0%;
      }

      &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(7) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(8) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
      &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(9) {
        box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
      }
    }
  }

  .carousel__slides {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    display: flex;
  }

  .carousel__slide {
    position: relative;
    display: block;
    flex: 1 0 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 300ms ease-out;
    vertical-align: top;
    box-sizing: border-box;
    white-space: normal;

    figure {
      display: flex;
      margin: 0;
    }

    div {
      @include aspect-ratio(3, 2);
      width: 100%;
    }

    img {
      display: block;
      flex: 1 1 auto;
      object-fit: cover;
      border-radius: unset;
    }

    figcaption {
      align-self: flex-end;
      padding: 20px 20px 0 20px;
      flex: 0 0 auto;
      width: 25%;
      min-width: 150px;
    }

    .credit {
      margin-top: 1rem;
      color: rgba(0, 0, 0, 0.5);
      display: block;
    }

    &.scrollable {
      overflow-y: scroll;
    }
  }

  .carousel__thumbnails {
    list-style: none;
    padding: 0;
    padding-top: 18px;
    margin: 0;
    display: flex;

    margin: 0 -10px;

    .carousel__slides + & {
      margin-top: 20px;
    }

    li {
      flex: 1 1 auto;
      max-width: calc((100% / 6) - 20px);
      margin: 0 10px;
      transition: all 300ms ease-in-out;
    }

    label {
      display: block;
      @include aspect-ratio(1, 1);

      &:hover,
      &:focus {
        cursor: pointer;

        img {
          box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
          transition: all 300ms ease-in-out;
          border-radius: unset;
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: unset;
    }
  }
}
