.overlay {
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}
.loginModal {
  z-index: 9;
  position: absolute;
  top: 20vh;
  left: 32.5vw;
  width: 35vw;
  height: fit-content;
  padding: 10px;
  color: #444;
  background: white;
  .logmodTop {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    .logmodTopText {
      width: 80%;
      font-size: 1.2em;
      font-weight: bold;
    }
    .logmodCloseX {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      padding-bottom: 3px;
      color: white;
      background: #0073ea;
      border: 2px solid #0073ea;
      &:hover {
        cursor: pointer;
        color: #0073ea;
        font-weight: bold;
        background: white;
      }
    }
  }
  .logmodBody {
    display: flex;
    padding: 10px;
    font-size: 0.9em;
    .logmodBodyLeft {
      flex: 1;
      max-width: 25vw;
      .logmodFieldRow {
        display: flex;
        margin-top: 10px;
        align-items: center;
        input[type='text'],
        input[type='password'] {
          width: 100%;
          &::placeholder {
            font-size: 0.8em;
            color: #bbb;
          }
        }
        .logmodFRLTitle {
          width: 80px;
          text-align: left;
        }
        .logmodFRLInput {
          flex: 1;
          max-width: 70%;
        }
        .forgotPasswordDiv {
          margin-bottom: 10px;
        }
      }
      .logmodBtnLoginDiv {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
      }
    }
    .logmodBodyRight {
      flex: 1;
      padding-left: 20px;
      border-left: 1px solid #ccc;
      .logmodUL {
        font-size: 1em;
      }
    }
    .logmodBodyTitle {
      margin-bottom: 10px;
      font-size: 1.5em;
    }
    .logmodBtnRight {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    .ibutton {
      margin: 0;
      padding: 5px 10px;
      color: white;
      font-size: 1.1em;
      font-weight: bold;
      background: #0073ea;
      border: 2px solid #0073ea;
      &:hover {
        cursor: pointer;
        color: #0073ea;
        background: white;
      }
    }
  }
}

.error {
  width: 70%;
  padding: 5px;
  margin: 10px 0 10px 20px;
  text-align: center;
  color: yellow;
  cursor: default;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.9);
}

.loadingBanner {
  position: absolute;
  height: 1%;
  background: skyblue;
  animation: loading 3s linear infinite;
}
@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.form-control {
  height: 34px;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  xxxborder-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
@media (max-width: 999px) {
  .loginModal {
    left: 10vw;
    width: 80vw;
  }
  .logmodFRLTitle {
    display: none !important;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .loginModal {
    left: 15vw;
    width: 70vw;
  }
  .logmodFRLTitle {
    display: none !important;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .loginModal {
    left: 20vw;
    width: 60vw;
  }
}
