.wrapperDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: 'Segoe UI light', 'Calibri', 'Helvetica', 'Arial', sans-serif;
  color: #444;
  background: #f4f4f4;
  p {
    margin: calc(4px + 0.75vw) 0 0;
    @media (max-width: 599px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media (min-width: 600px) and (max-width: 1200px) {
      xxxfont-size: calc(14px + (100vw - 600px) / 600 * (22-14));
      xxxline-height: calc((14px + (100vw - 600px) / 600 * (22-14)) + 6px);
    }
    @media (min-width: 1201px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #9cb6da; /*rgba(10, 60, 130, 1);*/
    color: #444;
    font-family: 'Segoe UI light', 'Calibri', 'Helvetica', 'Arial', sans-serif;
  }
  h1 {
    font-size: calc(12px + 1vw);
    line-height: calc((12px + 1vw) + 6px);
  }
  h2 {
    font-size: calc(10px + 1vw);
    line-height: calc((10px + 1vw) + 6px);
  }
  h3 {
    font-size: calc(8px + 1vw);
    line-height: calc((8px + 1vw) + 6px);
  }
  h4 {
    font-size: calc(5px + 1vw);
    line-height: calc((5px + 1vw) + 5px);
  }
}
