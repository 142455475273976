import { h, FunctionalComponent, JSX } from 'preact';
import style from './textArea.scss';

interface TextAreaProps extends JSX.HTMLAttributes<HTMLTextAreaElement> {
  onType?(value: string): void;
  onConfirm?(value: string): void;
}

export const TextArea: FunctionalComponent<TextAreaProps> = ({ onType, onConfirm, title = 'Ctrl + Enter to submit', class: className, ...props }) => {
  const handleKeyUp = (e: KeyboardEvent) => {
    const value = (e.srcElement as HTMLTextAreaElement).value;
    onType?.(value);
    if (e.ctrlKey && e.key === 'Enter') onConfirm?.(value);
  };

  return <textarea class={`${style.textArea} ${className ?? ''}`} title={title} onKeyUp={handleKeyUp} {...props}></textarea>;
};
