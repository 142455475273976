.minWidth {
  min-width: 110px !important;
  margin-bottom: 5px !important;
}
.homeContent {
  p {
    margin-top: 1.6rem;
    font-size: 1.2rem;
  }
}
.homeWrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  backdrop-filter: saturate(150%);
  xxxbackground: rgba(0, 0, 0, 0.5);
  .s1SplashDiv {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    .layerOne {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      .bgImgOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      img {
        min-width: 100%;
        min-height: 100%;
        xxxfilter: grayscale(65%);
        animation: panAroundImage 33s linear 0s infinite backwards;
      }
    }
    .layerTwo {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 5vh 8vw;
      .layerThree {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10vh 8vw 0;
        overflow: hidden;
        .homeLogo {
          text-align: center;
          img {
            width: 15.55vw;
          }
          .homeLogoText {
            position: relative;
            font-family: 'Segoe UI';
            xxxtext-shadow: 1px 1px 1px #6d6d6d;
            xxxanimation: homeLogoText 1s linear 4s forwards;
            .homeLogoTextStart {
              z-index: 0;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              text-align: center;
              xxxcolor: white;
              font-size: 1.84vw;
              animation: homeLogoTextStart 1s linear 5s forwards;
            }
            .homeLogoTextEnd {
              z-index: 1;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              text-align: center;
              color: white;
              font-size: 1.84vw;
              opacity: 0;
              animation: homeLogoTextEnd 1s linear 4s forwards;
            }
          }
        }
        .homeContent {
          margin: calc(5px + 5vw) 0 0;
          padding: 1vh 5vw 5vh;
          background: rgba(0, 0, 0, 0);
          animation: homeTextBG 1s linear 4s forwards;
          p {
            color: white; //#d0dcf3b4;
            font-size: 1.4vw;
            line-height: 1.9vw;
            xxxtext-shadow: 1px 1px 1px rgba(35, 35, 35, 1);
            animation: homeTextFG 1s linear 4s forwards;
          }
        }
      }
    }
    .layerFour {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 10vh 12vw 0;
      * {
        color: #eee;
        text-shadow: 1px 1px 2px rgba(35, 35, 35, 1);
      }
      .s1BtnProdsDIV {
        display: flex;
        justify-content: center;
      }
    }
  }
}
@keyframes panAroundImage {
  0% {
    transform: translateX(-0.25%) translateY(-25%) scale(1.25);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    transform: translateX(0%) translateY(0%) scale(1);
    opacity: 0;
  }
}
@keyframes homeTextBG {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.5);
  }
}
@keyframes homeTextFG {
  0% {
    color: #d0dcf3b4;
  }
  100% {
    color: white; //#d0dcf3;
  }
}
@keyframes homeLogoText {
  0% {
    color: white; //rgb(0, 71, 178)
  }
  100% {
    color: firebrick; //#03387e  lightcyan #d6e0f1;
  }
}
@keyframes homeLogoTextStart {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes homeLogoTextEnd {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
