import { h, FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { post } from '../../utils/ajax';
import style from './authorizeuser.scss';

/*  Called from Index.tsx - in response to admin clicking Authorize / Deny / Blacklist button   */
/*  Calls server.js route:  /authorizeNewUser   which redirects to */

interface AuthorizeUserProps {
  auth: string;
  bl: string;
  hash: string;
}
interface RegData {
  firstname: string;
  lastname: string;
  email: string;
  domain: string;
  dt_created: string;
}

export const AuthorizeUser: FunctionalComponent<AuthorizeUserProps> = ({ auth, bl, hash }) => {
  console.log(`***### AuthorizeUser.tsx ***###   bl:[${bl}]  auth:[${auth}] `);
  const [regData, setRegData] = useState<RegData | undefined>(undefined);
  const [appvText, setAppvText] = useState<any>(undefined);

  /* *********************************************************************** */
  useEffect(() => {
    post<RegData>('/authorizeNewUser', { auth, bl, hash }).then((data) => {
      const txtAuth = auth === '1' ? 'Approved' : 'Declined';
      const localDate = new Date(data.dt_created).toLocaleString('en-US', { timeZone: 'America/New_York' });
      const txtCreated = localDate.replace('T', ' ').replace('.000Z', '').substr(0, 16);
      const domain = data.email.split('@')[1];
      console.log(`@@@@@@@@@@@@@@@@@@  data.email: [${data.email}]   @@@@@@@@@@@@@@@@@@ `);
      console.log(`@@@@@@@@@@@@@@@@@@  domain: [${domain}]   @@@@@@@@@@@@@@@@@@ `);
      const modData = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        domain: domain,
        dt_created: txtCreated,
      };
      setRegData(modData);
      setAppvText(txtAuth);
      console.log(`AuthorizeUser.tsx useEffect XXX fn: [${data.firstname}] and LN: [${data.lastname}]`);
    });
  }, []);
  /* *********************************************************************** */

  const ansClass = appvText === 'Approved' ? style.yes : style.no; //Must be BELOW useEffect

  return (
    <div class={style.authorizeDiv}>
      <div class={style.header}>
        <h1>Flottec Websites</h1>
        <h2>New User Registration Request</h2>
      </div>
      <div class={style.body}>
        <div>
          Request Date: <div class={style.regDate}>{regData?.dt_created}</div>
        </div>
        <div class={style.decision}>
          Registrant:{' '}
          <div class={style.regName}>
            {regData?.firstname} {regData?.lastname}
          </div>
          <br />
          Email: <div class={style.regName}>{regData?.email}</div>
          <br />
          <br />
          <div class={style.regStatus}>
            Status: <div class={`${style.regAnswer} ${ansClass}`}> {appvText}</div>.
          </div>
        </div>
        {bl === '1' && (
          <div class={style.blackList}>
            Domain <span class={style.fgRed}>{regData?.domain}</span> is now blacklisted.
          </div>
        )}
      </div>
      <div class={style.footer}>Please close this window when finished.</div>
      {console.log({ bl })}
      {console.log('!!!!!!!!!!  regData: !!!!!!!!!!')}
      {console.log({ regData })}
    </div>
  );
};
