.lds_dual_ring {
  display: inline-block;
  width: 128px;
  height: 128px;
  position: absolute;
  &:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #000;
    border-color: #212121 transparent #212121 transparent;
    animation: lds_dual_ring 1.2s linear infinite;
  }
}

@keyframes lds_dual_ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
