.textInput {
  xxxbackground-color: #fff;
  background-position: right 8px center;
  background-repeat: no-repeat;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075);
  color: #24292e;
  font-size: 16px;
  line-height: 20px;
  min-height: 34px;
  outline: none;
  padding: 4px 10px;
  display: block;
  width: 100%;
  margin-bottom: 1%;
  &:focus {
    border-color: #2188ff;
    box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em rgba(3, 102, 214, 0.3);
    outline: none;
  }
  &:disabled {
    background-color: rgb(145, 145, 145);
    cursor: default;
  }
  &::placeholder {
    color: #aaa;
  }
}
