.emailAlreadyRegisteredDiv {
  padding: 15vh 20vw;
  color: #5f7282;
  font-size: 1.4rem;
  .greeting {
    margin-bottom: 15px;
    font-style: normal;
  }
  .confirmation {
    margin-bottom: 15px;
    font-style: normal;
  }
  .message {
    margin-bottom: 15px;
    font-style: normal;
  }
  .closing {
    .ty {
      margin-bottom: 15px;
      font-style: italic;
    }
  }
}
.fgBlue {
  color: #0062b7;
}
