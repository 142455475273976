import { h, FunctionalComponent, Fragment, JSX } from 'preact';
import style from './contact.scss';
import { InputText } from '../InputText/InputText';
import { useEffect, useState } from 'preact/hooks';
import { TextArea } from '../TextArea/TextArea';
import { get, post } from '../../utils/ajax';
//import { Redirect } from 'wouter-preact';

interface ContactProps {
  onClose(): void;
}
interface CaptchaData {
  id: number;
  ques: string;
  ans: string[];
}

export const Contact: FunctionalComponent<ContactProps> = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [company, setCompany] = useState('');
  const [landline, setLandline] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [rotater, setRotater] = useState(false);
  const [unready, setUnready] = useState<string | undefined>('Complete *Fields');
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [emailFieldBG, setEmailFieldBG] = useState('');
  const [blurb, setBlurb] = useState(false); //Contact Received message
  const [btnClicked, setBtnClicked] = useState(false);

  const blurbState = blurb ? style.showBlurb : style.hideBlurb;
  const blurbInner = blurb ? style.spinBlurb : '';
  const nameFieldBG = btnClicked && name === '' ? style.bgPink : '';
  const jobFieldBG = btnClicked && jobTitle === '' ? style.bgLightPink : '';
  const companyFieldBG = btnClicked && company === '' ? style.bgLightPink : '';
  const phoneFieldBG = btnClicked && landline === '' ? style.bgLightPink : '';
  const mobileFieldBG = btnClicked && mobile === '' ? style.bgLightPink : '';
  const messageFieldBG = btnClicked && message === '' ? style.bgPink : '';

  const [capErr, setCapErr] = useState(false);
  const [captcha, setCaptcha] = useState<CaptchaData | undefined>(undefined);
  const [lastNum, setLastNum] = useState<number | undefined>(0);
  const capAnsClass = capErr ? style.capErr : '';

  const handleMessageInput = (text: string) => {
    if (text.length > 10 && captchaSuccess) {
      setUnready(undefined);
    } else if (text.length > 10 && emailFieldBG === '') {
      setUnready('Answer Captcha');
    }
    if (email === '' || !email.includes('@') || !email.includes('.')) {
      setEmailFieldBG(style.bgPink);
    }
    setMessage(text);
  };
  const handleEmailInput = (text: string) => {
    if (message !== '' && (email === '' || !email.includes('@') || !email.includes('.'))) {
      setEmailFieldBG(style.bgPink);
    } else if (message.length > 10 && emailFieldBG === '') {
      setUnready('Answer Captcha');
    } else {
      setEmailFieldBG('');
    }
    setEmail(text);
  };
  const handleCaptchaAnswerChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value.toLowerCase();
    const capAuth = captcha !== undefined && captcha.ans !== undefined && captcha.ans.includes(val);
    if (capAuth === true) setUnready(undefined);
    setCapErr(false);
    setCaptchaSuccess(capAuth);
  };
  const handleCaptchaSubmit = () => {
    setCapErr(true);
  };

  const handleBtnSend = () => {
    if (email === '' || !email.includes('@') || !email.includes('.')) {
      setEmailFieldBG(style.bgPink);
    } else if (name === '' || message.length < 10) {
      setBtnClicked(true);
    } else {
      const objContact = {
        website: 'flottec',
        email,
        name,
        jobTitle,
        company,
        landline,
        mobile,
        message,
      };
      post('/sendContactEmail', objContact).then(() => {
        setName('');
        setEmail('');
        setJobTitle('');
        setCompany('');
        setLandline('');
        setMobile('');
        setMessage('');
        setBlurb(true); //?????
        setTimeout(() => {
          onClose();
        }, 3500);
      });
    }
    setRotater(false); //REMOVE REMOVE REMOVE REMOVE REMOVE REMOVE REMOVE
  };

  /* *********************************************************************** */
  useEffect(() => {
    get<CaptchaData>(`/getNextCaptcha?lastnum=${lastNum}&caller=Contact.tsx`).then((trio: CaptchaData) => {
      setLastNum(captcha?.id);
      setCaptcha(trio); //
    });
  }, []);
  /* *********************************************************************** */

  return (
    <Fragment>
      <div class={style.overlay}></div>
      <div class={`${style.contactModal} ${rotater ? style.rotate : ''}`}>
        <div class={style.card}>
          <div class={style.frontFace}>
            <div class={style.closeX} onClick={onClose}>
              {' X '}
            </div>
            <div class={style.header}>
              <div class={style.title}>Contact</div>
              <div class={style.text}>
                We look forward to reading your message<em>!</em>
              </div>
            </div>
            <div class={style.body}>
              <div class={style.twofer}>
                <div class={style.inputDiv}>
                  {console.log('emailFieldBG: ', emailFieldBG)}
                  <InputText
                    onType={handleEmailInput}
                    class={`${style.inputField} ${style.requiredField} ${emailFieldBG}`}
                    placeholder={'Email Address*'}
                    value={email}
                  />
                </div>
                <div class={style.inputDiv}>
                  <InputText
                    onType={setName}
                    class={`${style.inputField} ${style.requiredField} ${nameFieldBG}`}
                    placeholder={'Full Name*'}
                    value={name}
                  />
                </div>
              </div>

              <div class={style.twofer}>
                <div class={style.inputDiv}>
                  <InputText onType={setJobTitle} class={`${style.inputField} ${jobFieldBG}`} placeholder={'Job Title'} value={jobTitle} />
                </div>
                <div class={style.inputDiv}>
                  <InputText onType={setCompany} class={`${style.inputField} ${companyFieldBG}`} placeholder={'Company'} value={company} />
                </div>
              </div>

              <div class={style.twofer}>
                <div class={style.inputDiv}>
                  <InputText onType={setLandline} class={`${style.inputField} ${phoneFieldBG}`} placeholder={'Work Telephone'} value={landline} />
                </div>
                <div class={style.inputDiv}>
                  <InputText onType={setMobile} class={`${style.inputField} ${mobileFieldBG}`} placeholder={'Other contact'} value={mobile} />
                </div>
              </div>

              <div class={style.textareaDiv}>
                <TextArea
                  onType={handleMessageInput}
                  class={`${style.inputField} ${style.requiredField} ${messageFieldBG}`}
                  placeholder={'Message*'}
                  value={message}
                />
              </div>
            </div>
            <div class={style.accmodFieldRow}>
              <div class={style.accmodBtnLeft}>
                {captchaSuccess === false && unready === 'Answer Captcha' && (
                  <Fragment>
                    <div class={style.capTitle}>Are you a human?</div>
                    <div class={style.captcha}>
                      {/* {console.log({ captcha })} */}
                      <div class={style.capQues}>{captcha?.ques}</div>
                      <div class={style.capAns}>
                        <input
                          id='captcha'
                          class={`${capAnsClass} ${style.formControl}`}
                          type='text'
                          placeholder='your answer'
                          onKeyUp={handleCaptchaAnswerChange}
                        />
                        <button class={style.ibutton} onClick={handleCaptchaSubmit}>
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Fragment>
                )}
                {captchaSuccess === true && unready === undefined && <div class={style.thankYou}>Thank you, confirmed human!</div>}
              </div>
              <div class={style.accmodBtnRight}>
                <button class={style.ibutton} onClick={handleBtnSend} disabled={unready !== undefined}>
                  {unready === undefined ? 'Send' : unready === 'Complete *Fields' ? 'Complete *Fields' : '← Complete Captcha'}
                </button>
              </div>
            </div>
            <div class={`${style.blurb} ${blurbState}`}>
              <div class={`${style.inner} ${blurbInner}`}>Contact Received</div>
            </div>
          </div>
        </div>
        <div class={style.backFace}></div>
      </div>
    </Fragment>
  );
};
