.staticNoSlide {
  height: 11vw;
}
.footerDiv {
  flex-grow: 1;
  z-index: 2;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 2fr 2fr 1.25fr;
  grid-template-areas: 'colLeft colMiddle colRight';
  width: 100%;
  height: 100%;
  padding: 1vh 2%;
  font-size: 0.9vw;
  color: rgba(130, 130, 130, 1);
  background: white;
  .footLeft {
    grid-area: colLeft;
    width: 100%;
    height: 100%;
    padding: 1%;
    background: white;
    .logos {
      display: flex;
      width: 100%;
      height: 100%;
      .logo {
        width: 50%;
        height: 100%;
        display: grid;
        place-items: center;
        img {
          width: 9vw;
        }
      }
    }
  }
  .footMiddle {
    background: white;
    border-left: 1px solid rgba(200, 200, 200, 0.3);
    padding: 1%;
    grid-area: colMiddle;
    .title {
      text-align: center;
    }
    .sitemapDiv {
      xxxpadding: 0 100px;
      .sitemapRow {
        display: flex;
        margin-top: 0.5vw;
        text-align: center;
        line-height: calc(8px + 1vw);
        .siteLink {
          flex: 1;
          color: #828282;
          a {
            text-decoration: none;
            color: #828282;
            font-size: calc(5px + 0.5vw);
            &:visited {
              text-decoration: none;
              font-size: calc(5px + 0.5vw);
            }
            &:hover {
              xxxtext-decoration: underline;
              color: #222;
              font-size: calc(5px + 0.5vw);
            }
          }
        }
      }
    }
  }
  .footRight {
    background: white;
    border-left: 1px solid rgba(200, 200, 200, 0.3);
    padding: 1%;
    grid-area: colRight;
    .title {
      text-align: right;
      a,
      a:visited,
      a:hover {
        color: #0a3c82;
      }
    }
    .contactDiv {
      div {
        text-align: right;
        font-size: calc(5px + 0.5vw);
      }
    }
  }
  .title {
    padding-bottom: 10px;
    font-size: 1.25vw;
    cursor: pointer;
    color: rgba(10, 60, 130, 1);
  }
}
.version {
  position: absolute;
  bottom: 0;
  right: 0;
  color: transparent;
  font-size: 1.2rem;
}
.UNUSEDfooterLanding {
  display: none !important;
  z-index: 1;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'colLeft colMiddle colRight';
  width: 100%;
  padding: 1vh 7%;
  color: rgba(130, 130, 130, 1);
  background: white;
  .footLeft {
    xborder: 1px solid green;
    padding: 1%;
    grid-area: colLeft;
    .logos {
      display: flex;
      .logo {
        width: 50%;
        a {
          img {
            width: 9vw;
          }
        }
      }
    }
  }
  .footMiddle {
    xborder: 1px solid yellow;
    border-left: 1px solid rgba(200, 200, 200, 0.3);
    padding: 1%;
    grid-area: colMiddle;
    .title {
      text-align: center;
    }
    .sitemapDiv {
      padding: 0 100px;
      .sitemapRow {
        display: flex;
        margin-top: 20px;
        text-align: center;
        .siteLink {
          margin-left: 20px;
          color: #828282;
          a {
            text-decoration: none;
            color: #828282;
            &:visited {
              text-decoration: none;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .footRight {
    border-left: 1px solid rgba(200, 200, 200, 0.3);
    padding: 1%;
    grid-area: colRight;
    .title {
      text-align: right;
    }
    .contactDiv {
      div {
        text-align: right;
      }
    }
  }
  .title {
    padding-bottom: 10px;
    font-size: 1.7em;
    color: rgba(10, 60, 130, 1);
  }
}
@media (max-width: 999px) {
  .footerDiv {
    display: block !important;
  }
  .footerDiv .footLeft .logos .logo {
    display: flex;
    justify-content: space-evenly;
  }
}
