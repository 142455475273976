.text-area {
  resize: vertical;
  width: 100%;
  min-height: 250px;
  max-height: 500px;
  padding: 10px;
  border-radius: 5px;
  &:disabled {
    cursor: not-allowed;
    background: rgb(55, 55, 55);
  }
  &::placeholder {
    color: #ccc;
  }
}
