import { h, FunctionalComponent, Fragment, JSX } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useLocation } from 'wouter-preact';
import { AppState } from '../../store/store';
import { get, post } from '../../utils/ajax';
import { Button } from '../Button/Button';
import style from './register.scss';

interface RegisterProps {
  onDoLogin(): void;
  onSuccess(data: AppState): void;
  onClose(): void;
}
interface CaptchaData {
  id: number;
  ques: string;
  ans: string[];
}

export const Register: FunctionalComponent<RegisterProps> = ({ onClose, onSuccess, onDoLogin }) => {
  const [, setLocation] = useLocation();
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [eml1, setEml1] = useState<string>('');
  const [telno, setTelno] = useState<string>('');
  const [pw1, setPw1] = useState<string>('');
  const [pw2, setPw2] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [pwdClass, setPwdClass] = useState<string>('');
  const [pwd2Class, setPwd2Class] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [langClicked, setLangClicked] = useState(false);
  const [displayContinue, setDisplayContinue] = useState(true);
  const [allFieldsCompleted, setAllFieldsCompleted] = useState(false);
  const [langClickedError, setLangClickedError] = useState(false);
  const [capErr, setCapErr] = useState(false);
  const [captcha, setCaptcha] = useState<CaptchaData | undefined>(undefined);
  const [lastNum, setLastNum] = useState<number | undefined>(0);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);
  const [passwordTooShort, setPasswordTooShort] = useState(false);

  const testAllFieldsCompleted = (el: string) => {
    //Must be above the handle... routines, which call it
    if (!langClicked && el === 'continue' && pw2 !== '') {
      setLangClickedError(true);
    } else {
      setLangClickedError(false);
    }
    if (
      firstname !== '' &&
      lastname !== '' &&
      company !== '' &&
      jobTitle !== '' &&
      eml1 !== '' &&
      telno !== '' &&
      pw1 !== '' &&
      pw2 !== '' &&
      langClicked
    ) {
      setAllFieldsCompleted(true);
      setDisplayContinue(false);
    }
  };
  const handleLangClick = () => {
    setLangClicked(true);
    testAllFieldsCompleted('');
  };
  const handleLangChange = (e: JSX.TargetedEvent<HTMLSelectElement, Event>) => {
    const val = e.currentTarget.value;
    setLanguage(val);
    setLangClicked(true);
    testAllFieldsCompleted('');
  };
  const handleFNameChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setFirstname(val);
    testAllFieldsCompleted('');
  };
  const handleLNameChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setLastname(val);
    testAllFieldsCompleted('');
  };
  const handleCompChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setCompany(val);
    testAllFieldsCompleted('');
  };
  const handleJobTChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setJobTitle(val);
    testAllFieldsCompleted('');
  };
  const handleEml1Change = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setEml1(val);
    testAllFieldsCompleted('');
  };
  const handleTelnoChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setTelno(val);
    testAllFieldsCompleted('');
  };
  const handlePwd1Blur = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    if (val.length < 6) {
      setPwdClass('error');
      setPasswordTooShort(true);
    }
  };
  const handlePwd2Blur = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    if (val.length < 6) {
      setPwdClass('error');
      setPasswordTooShort(true);
    }
  };
  const handlePwd1Change = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    if (val.length < 6) {
      setPwdClass('error');
      // setPasswordTooShort(true);
    } else {
      setPw1(val);
      setPwdClass('');
      testAllFieldsCompleted('');
    }
  };
  const handlePwd2Change = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    if (val !== pw1) {
      setPwd2Class('error');
      // setPasswordTooShort(true);
    } else {
      setPw2(val);
      setPwd2Class('');
      testAllFieldsCompleted('');
    }
  };
  const handleMessageChange = (e: JSX.TargetedEvent<HTMLTextAreaElement, Event>) => {
    const val = e.currentTarget.value;
    setMessage(val);
    testAllFieldsCompleted('');
  };

  const handleContinueClick = () => {
    testAllFieldsCompleted('continue');
  };

  const handleCaptchaAnswerChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value.toLowerCase();
    const capAuth = captcha !== undefined && captcha.ans !== undefined && captcha.ans.includes(val);
    setCapErr(false);
    setCaptchaSuccess(capAuth);
  };
  const handleCaptchaSubmit = () => {
    setCapErr(true);
  };

  const handleRegistrationSubmit = async () => {
    // const ff = [firstname, lastname, company, jobTitle, eml1, telno, pw1, message, language];
    // ff.forEach((item) => {
    //   console.log({ item });
    // });
    console.log('Register.tsx handleRegistrationSubmit BEGIN');
    console.log(`Value of pw1:[${pw1}] and pw2:[${pw2}]`);

    if (pw1 !== pw2) {
      console.log('No Match PW');
      setPasswordsDoNotMatch(true);
      return false;
    }
    if (pw1.length < 6 || pw2.length < 6) {
      console.log('Too Short PW');
      setPasswordTooShort(true);
      return false;
    }

    console.log('Register.tsx handleRegistrationSubmit NEXT');

    try {
      // console.log('Register.tsx handleRegistrationSubmit TRY');
      const auth = await post<AppState>('/RegisterNewUser', { firstname, lastname, company, jobTitle, eml1, telno, pw1, language, message });

      console.log('Register.tsx handleRegistrationSubmit NEXT 02');

      const name = `${firstname} ${lastname}`;
      if (auth && auth.userData && auth?.userData?.errorMode > 1) {
        /*  Success. Normal registration may proceed.  */

        console.log('Register.tsx handleRegistrationSubmit NEXT 03');

        onSuccess(auth);
        setLocation(`/accountProcessing/${eml1}/name/${name}`);
      } else if (auth && auth.userData && auth?.userData?.errorMode > 0) {
        /*  Fail. Email address already registered / account already exists.  */
        setLocation(`/newAccountRegistration/error/id/${eml1}`);
        onClose();
      } else if (auth && auth.userData && auth?.userData?.errorMode < 1) {
        /*  Fail. Email domain is blacklisted.  */
        //<BlacklistedDomain name={name} email={eml1} />;
        onClose();
        setLocation(`/newAccountProcessing/${eml1}/name/${name}`);
      }
    } catch {
      // setError(true);
      console.log('Error');
    }
  };

  const pwdFieldClass = pwdClass === 'error' ? style.capErr : '';
  const pwd2FieldClass = pwd2Class === 'error' ? style.capErr : '';
  const capAnsClass = capErr ? style.capErr : '';
  const contLinkClass = pw2 !== '' ? style.contLinkOkay : '';
  const showMessageClass = captchaSuccess ? style.messageShow : style.messageHide;
  const langErrorClass = langClickedError === true ? style.capErr : '';
  const langClickedClass = langClicked === true ? '' : style.fgGrey;

  /* *********************************************************************** */
  useEffect(() => {
    console.log('Register.tsx useEffect');
    get<CaptchaData>(`/getNextCaptcha?lastnum=${lastNum}&caller=Register.tsx`).then((trio: CaptchaData) => {
      console.log(`captchaTrio: ${JSON.stringify(trio)}`);
      setLastNum(captcha?.id);
      setCaptcha(trio); //
    });
  }, []);
  /* *********************************************************************** */

  return (
    <Fragment>
      <div class={style.overlay}></div>
      <div class={style.createAccountModal}>
        <div class={style.accmodTop}>
          <div class={style.accmodCloseX} onClick={onClose}>
            {' x '}
          </div>
          <div class={style.accmodTopText}>Create</div>
        </div>
        <div class={style.accmodBody}>
          <div class={style.accmodBodyLeft}>
            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Name</div>
              <div class={style.accmodFRLInput}>
                <input id='fname' class={style.formControl} type='text' placeholder='First name*' onChange={(e: any) => handleFNameChange(e)} />
              </div>
              <div class={style.accmodFRLInput}>
                <input id='lname' class={style.formControl} type='text' placeholder='Last name*' onChange={(e: any) => handleLNameChange(e)} />
              </div>
            </div>

            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Company</div>
              <div class={style.accmodFRLInput}>
                <input id='company' class={style.formControl} type='text' placeholder='Company name*' onChange={(e: any) => handleCompChange(e)} />
              </div>
              <div class={style.accmodFRLInput}>
                <input id='jobtitle' class={style.formControl} type='text' placeholder='Job Title*' onChange={(e: any) => handleJobTChange(e)} />
              </div>
            </div>

            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Email</div>
              <div class={style.accmodFRLInput}>
                <input id='email1' class={style.formControl} type='text' placeholder='Email*' onChange={(e: any) => handleEml1Change(e)} />
              </div>
              <div class={style.accmodFRLInput}>
                <input id='telno' class={style.formControl} type='text' placeholder='Telephone Number*' onChange={(e: any) => handleTelnoChange(e)} />
              </div>
            </div>

            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Password</div>
              <div class={style.accmodFRLInput}>
                <input
                  id='password1'
                  class={`${pwdFieldClass} ${style.formControl}`}
                  type='password'
                  placeholder='Password*'
                  onChange={(e: any) => handlePwd1Change(e)}
                  onBlur={(e: any) => handlePwd1Blur(e)}
                />
              </div>
              <div class={style.accmodFRLInput}>
                <input
                  id='password2'
                  class={`${pwd2FieldClass} ${style.formControl}`}
                  type='password'
                  placeholder='Confirm Password*'
                  onChange={(e: any) => handlePwd2Change(e)}
                  onBlur={(e: any) => handlePwd2Blur(e)}
                />
              </div>
            </div>

            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Language</div>
              <div class={style.accmodFRLInput}>
                <select
                  id='regLang'
                  class={`${style.formControl} ${style.regSelect} ${langClickedClass} ${langErrorClass}`}
                  onClick={handleLangClick}
                  onChange={(e: any) => handleLangChange(e)}
                >
                  <option style={'color: #555;'} value='Choose'>
                    Choose*
                  </option>
                  <option value='en'>English</option>
                  <option value='es'>Espanol</option>
                </select>
              </div>
              <div class={style.accmodFRLInput}>
                {displayContinue && (
                  <div class={`${style.linkContinue} ${contLinkClass}`} onClick={handleContinueClick}>
                    continue
                  </div>
                )}
              </div>
            </div>

            <div class={`${style.accmodFieldRow} ${showMessageClass}`}>
              <div class={style.accmodFRLTitle}>Message:</div>
              <div class={style.accmodFRLInput}>
                <textarea
                  id='message'
                  class={`${style.formControl} ${style.taMessage}`}
                  type='text'
                  placeholder='Message (optional)'
                  onChange={(e: any) => handleMessageChange(e)}
                ></textarea>
              </div>
            </div>

            <div class={style.accmodFieldRow}>
              {allFieldsCompleted === true ? (
                <Fragment>
                  <div class={style.accmodBtnLeft}>
                    {captchaSuccess === false ? (
                      <Fragment>
                        <div class={style.ablTitle}>Are you a human?</div>
                        <div class={style.captcha}>
                          {console.log({ captcha })}
                          <div class={style.capQues}>{captcha?.ques}</div>
                          <div class={style.capAns}>
                            <input
                              id='captcha'
                              class={`${capAnsClass} ${style.formControl}`}
                              type='text'
                              placeholder='Answer'
                              onKeyUp={handleCaptchaAnswerChange}
                            />
                            <button class={style.ibutton} onClick={handleCaptchaSubmit}>
                              Go
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <div class={style.thankYou}>Thank you, confirmed human!</div>
                    )}
                  </div>
                  <div class={style.accmodBtnRight}>
                    {captchaSuccess === true && allFieldsCompleted === true && (
                      <button class={style.ibutton} onClick={handleRegistrationSubmit}>
                        Create account
                      </button>
                    )}
                  </div>
                </Fragment>
              ) : (
                <div class={`${style.accmodBtnLeft} ${style.plsCompleteMsg}`}>Please complete all fields</div>
              )}
            </div>
          </div>
          <div class={style.accmodBodyRight}>
            <div class={style.accmodBodyTitle}>Already have an account?</div>
            <div class={style.accmodFieldRow}>
              <a href='#' onClick={onDoLogin}>
                Login to your Account
              </a>
            </div>
          </div>
        </div>
      </div>
      {passwordsDoNotMatch && (
        <div class={style.passNoMatchMessageDiv}>
          <div class={style.passNoMatchInner}>
            <div class={style.passNoMatchMessage}>Password fields do not match. Please erase both and try again.</div>
            <div class={style.passNoMatchBtnDiv}>
              <Button onClick={() => setPasswordsDoNotMatch(false)}>OK</Button>
            </div>
          </div>
        </div>
      )}
      {passwordTooShort && (
        <div class={style.passNoMatchMessageDiv}>
          <div class={style.passNoMatchInner}>
            <div class={style.passNoMatchMessage}>Password is too short. Minimum password length is 6 characters.</div>
            <div class={style.passNoMatchBtnDiv}>
              <Button onClick={() => setPasswordTooShort(false)}>OK</Button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
