import { h, Component, VNode, JSX } from 'preact';
import style from './inputText.scss';

interface InputTextProps extends JSX.HTMLAttributes<HTMLInputElement> {
  onType?: (value: string) => void;
  onConfirm?: (value: string) => void;
  onEscape?: () => void;
  onCustomBlur?: (value: string) => void;
}

/**
 * Creates a text input element with a label 'name'.
 * @export
 * @class TextInput
 * @extends {Component<TextInputProps>}
 */
export class InputText extends Component<InputTextProps> {
  public render({ value, disabled, class: className, ...props }: InputTextProps): VNode {
    const noUndef = value === undefined ? '' : value;
    return (
      <input
        class={`${style.textInput} ${className || ''}`}
        type='text'
        value={noUndef}
        disabled={disabled}
        onKeyUp={this.handleKeyPress}
        onBlur={this.handleBlur}
        {...props}
      />
    );
  }

  /** Handle input keydown event */
  private handleKeyPress = (e: KeyboardEvent) => {
    const el = e.srcElement as HTMLInputElement;
    const value = el.value;
    if (this.props.onType) this.props.onType(value);
    if (e.key === 'Enter' && this.props.onConfirm) this.props.onConfirm(value);
    if (e.key === 'Escape' && this.props.onEscape) this.props.onEscape();
  };

  /** Handle leaving input element event **/
  private handleBlur = (e: FocusEvent) => {
    const el = e.srcElement as HTMLInputElement;
    const value = el.value;
    if (this.props.onCustomBlur) this.props.onCustomBlur(value);
  };
}
