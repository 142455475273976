import { h, FunctionalComponent, Fragment } from 'preact';
import style from './footer.scss';
import { get } from '../../utils/ajax';
import { useLocation } from 'wouter-preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { AppContext } from '../../store/store';

import logoFlottecMexico from 'url:../../img/Flottec_LOGO_Mexico_175.png';
import logoFlottecChile from 'url:../../img/Flottec_LOGO_Chile_175.png';
import logoChemiqa from 'url:../../img/Flottec_LOGO_Canada_175.png';
import { ActiveLink } from '../ActiveLink/ActiveLink';

interface FooterProps {
  onContact(): void;
}

export const Footer: FunctionalComponent<FooterProps> = ({ onContact }) => {
  const [currLoc] = useLocation();
  const { language: lang } = useContext(AppContext);
  const [flottecAddress, setFlottecAddress] = useState<string[] | undefined>(undefined);

  if (currLoc === '/cms' || currLoc === '/CMS') {
    // Swap back to CMS when ready...
    return null;
  }
  /* *********************************************************************** */
  useEffect(() => {
    get<string>('/getFooterAddress').then((data: any) => {
      // console.log('Footer.tsx useEffect /getScratchpadValue');
      const flotAddress = JSON.parse(data);
      setFlottecAddress(flotAddress.flottec.split('|'));
    });
  }, []);
  /* *********************************************************************** */
  const createMarkup = (htmlString: string) => ({ __html: htmlString });

  const footyClass = `${style.footerDiv} ${style.staticNoSlide}`;
  const rdHtml = lang === 'en' ? 'R&amp;D' : 'I&amp;D';
  const networkHtml = lang === 'en' ? 'Network' : 'Red&nbsp;Global';
  const contactHtml = lang === 'en' ? 'Contact&nbsp;Us' : 'Contactanos';
  return (
    <Fragment>
      <div id='footerDiv' class={footyClass}>
        <div class={style.footLeft}>
          <div class={style.title}>Partners</div>
          <div class={style.logos}>
            <div class={style.logo}>
              <a href='http://flottec.mx' target='_blank' rel='noopener noreferrer'>
                <img src={logoFlottecMexico} />
              </a>
            </div>
            <div class={style.logo}>
              <a href='https://flottec.cl' target='_blank' rel='noopener noreferrer'>
                <img src={logoFlottecChile} />
              </a>
            </div>
            <div class={style.logo}>
              <a href='https://flottec.ca' target='_blank' rel='noopener noreferrer'>
                <img src={logoChemiqa} />
              </a>
            </div>
          </div>
        </div>
        <div class={style.footMiddle}>
          <div class={style.title}>Site Map</div>
          <div class={style.sitemapDiv}>
            <div class={style.sitemapRow}>
              <div class={style.siteLink}>
                <ActiveLink href='/' exact>
                  Home
                </ActiveLink>
              </div>
              <div class={style.siteLink}>
                <ActiveLink href='/products' exact>
                  {lang === 'en' ? 'Products' : 'Productos'}
                </ActiveLink>
              </div>
              <div class={style.siteLink}>
                <ActiveLink href='/rd' exact>
                  <div dangerouslySetInnerHTML={createMarkup(rdHtml)} />
                </ActiveLink>
              </div>
              <div class={style.siteLink}>
                <ActiveLink href='/sds' exact>
                  {lang === 'en' ? 'MSDS/SDS' : 'HDS/HDSDM'}
                </ActiveLink>
              </div>
              <div class={style.siteLink}>
                <ActiveLink href='/network' exact>
                  <div dangerouslySetInnerHTML={createMarkup(networkHtml)} />
                </ActiveLink>
              </div>
              <div class={style.siteLink}>
                <ActiveLink href='/company' exact>
                  {lang === 'en' ? 'Company' : 'Compañía'}
                </ActiveLink>
              </div>
              <div class={style.siteLink}>
                <a href={'#'} onClick={() => onContact()}>
                  <div dangerouslySetInnerHTML={createMarkup(contactHtml)} />
                </a>
              </div>
            </div>
            <div class={style.sitemapRow}>
              <div class={style.siteLink}>
                <a href={'https://flottec.mx'}>Flottec Mexico</a>
              </div>
              <div class={style.siteLink}>
                <a href={'https://flottec.cl'}>Flottec Chile</a>
              </div>
              <div class={style.siteLink}>
                <a href={'https://flottec.ca'}>Flottec Canada</a>
              </div>
              <div class={style.siteLink}>
                <a href={'/gallery'}>Flottec&nbsp;Gallery</a>
              </div>
            </div>
          </div>
        </div>
        <div class={style.footRight}>
          <div class={style.title}>
            <a href='#' onClick={() => onContact()}>
              Contact
            </a>
          </div>
          <div class={style.contactDiv}>
            {flottecAddress !== undefined &&
              flottecAddress.map((d) => {
                return <div>{d}</div>;
              })}
          </div>
        </div>
      </div>
      {/*console.log('<!-- *** HEY!!!!! REMEMBER *** to `npm run build` before publish -->')*/}
      <div class={style.version}>0.02402111310</div>
      {/*console.log('<!-- *** HEY!!!!! REMEMBER *** to `npm run build` before publish -->')*/}
    </Fragment>
  );
};
