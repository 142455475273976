import { h, FunctionalComponent, Fragment } from 'preact';
// import { useContext } from 'preact/hooks';
import { useContext, useEffect, useState } from 'preact/hooks';
import style from './header.scss';
import { ActiveLink } from '../ActiveLink/ActiveLink';
import { IconLock } from '../GitHub Icons/IconLock';
import { AppContext, Language } from '../../store/store';
import { IconKey } from '../GitHub Icons/IconKey';
import { useLocation, Link, Redirect } from 'wouter-preact';
import { Autocompleter } from '../Autocompleter/Autocompleter';

import logoFlottec from 'url:../../img/logo_flottec_chemiqa.jpg';
//import logoFlottec from 'url:../../img/Flottec_LOGO_Flottec_175.png';
import flagCanada from 'url:../../img/flag-big-ca.png';
import flagChile from 'url:../../img/flag-big-cl.png';
import flagMexico from 'url:../../img/flag-big-mx.png';
// import flagUSA from 'url:../../img/flag-big-usa.png';

/*  Called from Index.tsx  */
interface HeaderProps {
  onLogin(): void;
  onLangChange(lang: Language): void;
}

export const Header: FunctionalComponent<HeaderProps> = ({ onLogin, onLangChange }) => {
  const [location] = useLocation();
  const [oFlowHidden, setOFlowHidden] = useState(true);
  const [rollUpClass, setRollUpClass] = useState(style.navRollUp);
  const { userData, language: lang } = useContext(AppContext);
  console.log(`Header.tsx --- authenticated:[${userData?.authenticated}] `); // location: [${location}]
  const handleChangeLang = (value: Language) => {
    onLangChange(value);
    setRollUpClass(style.noRollUp);
  };
  //const rollUpClass = location === '/' ? style.navRollUp : style.noRollUp;

  /* *********************************************************************** */
  useEffect(() => {
    //Only want this test to run once, so put it into a useEffect
    //Rats... the "run-once" didn't really work... it runs twice (but only twice)
    const currDT = new Date();
    const lastRollUp = localStorage.getItem('floLastHead');
    // if (lastRollUp !== null) {
    //   const rollupDelta = +currDT - +lastRollUp;
    //   console.log('*****************************************');
    //   console.log({ rollupDelta });
    //   console.log('*****************************************');
    // }

    if (lastRollUp === null || +currDT - +lastRollUp > 60000) {
      //60 secs
      // console.log('GREATER than 5 secs - ROLL UP!');
      setRollUpClass(style.navRollUp);
      localStorage.setItem('floLastHead', `${+currDT}`);
    } else if (+currDT - +lastRollUp < 8000) {
      //Do nothing, this is a repaint!
    } else {
      // console.log('Not greater than 5 secs - NO ROLL UP!');
      setRollUpClass(style.noRollUp);
    }
  }, []);
  /* *********************************************************************** */

  /* *********************************************************************** */
  useEffect(() => {
    // console.log(`Header.tsx useEffect authenticated:[${userData?.authenticated}] `);
    setTimeout(function () {
      setOFlowHidden(false);
    }, 5000);
  }, []);
  /* *********************************************************************** */

  return (
    <Fragment>
      <header class={`${oFlowHidden ? style.oFlowHidden : ''} ${style.navHeader} ${rollUpClass}`}>
        {location !== '/' ? (
          <div class={style.logoLeft}>
            <Link href='/'>
              <a class={style.clientLogoALink}>
                <img src={logoFlottec}></img>
              </a>
            </Link>
            <Autocompleter />
          </div>
        ) : (
          <Autocompleter />
        )}
        <nav>
          <ActiveLink href='/products' exact>
            {lang === 'en' ? 'Products' : 'Productos'}
          </ActiveLink>
          <ActiveLink href='/rd' exact>
            {lang === 'en' ? 'R&D' : 'I&D'}
          </ActiveLink>
          <ActiveLink href='/sds' exact>
            {lang === 'en' ? 'MSDS/SDS' : 'HDS/HDSDM'}
          </ActiveLink>
          <ActiveLink href='/network' exact>
            {lang === 'en' ? 'Network' : 'Red Global'}
          </ActiveLink>
          <ActiveLink href='/company' exact>
            {lang === 'en' ? 'Company' : 'Compañía'}
          </ActiveLink>
          <ActiveLink href='/gallery' exact>
            {lang === 'en' ? 'Gallery' : 'Compañía'}
          </ActiveLink>
          {userData?.authenticated && userData?.privLevel && userData?.privLevel > 1 && (
            <ActiveLink href='/cms' exact>
              <span class={style.btnCms}>CMS</span>
            </ActiveLink>
          )}
          {userData?.authenticated ? (
            <a href='/logoff' title='Sign Out'>
              <IconLock size={3} />
            </a>
          ) : (
            <a title='Sign In' onClick={() => onLogin()}>
              <IconKey size={3} />
            </a>
          )}
        </nav>
        {location !== '/cms' && (
          <div class={style.flagsLangDiv}>
            <div class={style.flagsDiv}>
              {/* <a href='/'>
                <img src={flagUSA} />
              </a> */}
              <a href='https://flottec.ca'>
                <img src={flagCanada} />
              </a>
              <a href='https://flottec.mx'>
                <img src={flagMexico} />
              </a>
              <a href='https://flottec.cl'>
                <img src={flagChile} />
              </a>
            </div>
            <div class={style.langDiv}>
              <span class={style.langDivText}>{lang === 'en' ? 'Language' : 'Lenguaje'}:&nbsp;</span>
              <select
                value={lang}
                class={style.langSelect}
                onChange={(e: Event) => handleChangeLang((e.srcElement as HTMLSelectElement).value as Language)}
              >
                <option value='en'>English</option>
                <option value='es'>Espanol</option>
              </select>
            </div>
          </div>
        )}
        {location === '/cms' && userData?.authenticated === false && <Redirect to='/' />}
      </header>
    </Fragment>
  );
};
