/* eslint-disable react/display-name */
import { h, FunctionalComponent, Fragment } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import style from './home.scss';
import Markup from 'preact-markup';
import Typography from '@material-ui/core/Typography';
import { get } from '../../utils/ajax';
import { AppContext, CMSContent } from '../../store/store';

interface PageCMS {
  olayColorHex: string;
  olayOpacity: string;
  bgImgGreyscale: string;
  bgImgBlur: string;
  sloganTextColorHex: string;
  sloganShadowColorHex: string;
  pageTextColorHex: string;
  pageTextBgColorHex: string;
}

export const Home: FunctionalComponent = () => {
  const { language: lang } = useContext(AppContext);
  const [pageContent, setpageContent] = useState<string | undefined>(undefined);
  const [pageCMS, setPageCMS] = useState<PageCMS>({
    olayColorHex: '#000000',
    olayOpacity: '0',
    bgImgGreyscale: '83',
    bgImgBlur: '0',
    sloganTextColorHex: '#03387e',
    sloganShadowColorHex: '#000000',
    pageTextColorHex: '#ffffff',
    pageTextBgColorHex: '#000000',
  });

  /* *********************************************************************** */
  useEffect(() => {
    const companyTab = 'Home';
    const editCompany = 'flotUsa';
    get<CMSContent[]>(`/getPageHTML?tab=${companyTab}&company=${editCompany}&lang=${lang}`).then((homeContent) => {
      setpageContent(homeContent[0].html);
    });
  }, [lang]);
  /* *********************************************************************** */
  useEffect(() => {
    get('/getHomePageCmsSettings').then((homeCMS) => {
      const homeOlay = JSON.parse(homeCMS.homeOlay);
      const homeText = JSON.parse(homeCMS.homeText);
      const olayOpac = homeOlay.olayOpacity.toString(16);
      // xonsole.log(`olayOPac: ${olayOpac}`);
      // xonsole.log('homeOlay: ');
      // xonsole.log({ homeOlay });
      const cmsSettings = {
        olayColorHex: homeOlay.olayColorHex,
        olayOpacity: olayOpac,
        bgImgGreyscale: homeOlay.imgGreyscale,
        bgImgBlur: homeOlay.imgBlur,
        sloganTextColorHex: homeText.sloganTextColorHex,
        sloganShadowColorHex: homeText.sloganShadowColorHex,
        pageTextColorHex: homeText.pageTextColorHex,
        pageTextBgColorHex: homeText.pageTextBgColorHex,
      };
      setPageCMS(cmsSettings);
    });
  }, []);
  /* *********************************************************************** */

  return (
    <Fragment>
      <div class={style.homeWrap}>
        <div class={style.s1SplashDiv}>
          <div class={style.layerOne} style={`filter: blur(${pageCMS.bgImgBlur}px);`}>
            <div class={style.bgImgOverlay} style={`background: ${pageCMS.olayColorHex}${pageCMS.olayOpacity};`}></div>
            <img style={`filter: grayscale(${pageCMS.bgImgGreyscale}%);`} src={'/img/bg.webp'} />
          </div>
          <div class={style.layerTwo}>
            <div class={style.layerThree}>
              <div class={style.homeLogo}>
                <img src={'/img/FT-Logo-Chile.svg'} />
                <div class={style.homeLogoText}>
                  {/*  Called sloganShadowColor b/c orig intended for that, and still called in the DB JSON */}
                  <div style={`color:${pageCMS.sloganShadowColorHex};`} class={style.homeLogoTextStart}>
                    {lang === 'en'
                      ? 'Maximizing the Value of Flotation Chemicals Technology'
                      : 'Maximización del valor de la tecnología de productos químicos de flotación'}
                  </div>
                  <div style={`color:${pageCMS.sloganTextColorHex};`} class={style.homeLogoTextEnd}>
                    {lang === 'en'
                      ? 'Maximizing the Value of Flotation Chemicals Technology'
                      : 'Maximización del valor de la tecnología de productos químicos de flotación'}
                  </div>
                </div>
              </div>
              <div id={'homeContent'} style={'color:red;'} class={style.homeContent}>
                {pageContent !== undefined && (
                  <Typography>
                    <Markup markup={pageContent} trim={false} type='html' />
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
