import { h, FunctionalComponent } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { GalleryItem } from '../../Components/DatabaseManager/Components/Gallery/ManageGallery';
import { AppContext } from '../../store/store';
import { get } from '../../utils/ajax';
import style from './galleria.scss';
//import { useState } from 'preact/hooks';
//import { Redirect } from 'wouter-preact';
// interface GalleriaProps {}  // <GalleriaProps>

interface RowItem {
  img: string;
  ttl: string;
  txt: string;
}

export const Galleria: FunctionalComponent = () => {
  const { language: lang } = useContext(AppContext);
  const [tableData, setTableData] = useState<RowItem[] | undefined>(undefined);

  /* *********************************************************************** */
  useEffect(() => {
    console.log('ROUTE Gallery.tsx useEffect');
    get<GalleryItem[]>('/getAllGalleryItems').then((allItems) => {
      const data = allItems.map((d) => {
        return { img: d.img, ttl: lang === 'en' ? d.title_en : d.title_es, txt: lang === 'en' ? d.blurb_en : d.blurb_es };
      });
      setTableData(data);
    });
  }, []);
  /* *********************************************************************** */

  return (
    <div class={style.galleriaDiv}>
      <section>
        <div class={style.container}>
          <div class={style.carousel}>
            <input type='radio' name='slides' checked={true} id='slide-1' />
            {tableData !== undefined &&
              tableData
                .filter((item, idx) => {
                  return idx > 0 && idx < 17;
                })
                .map((row: RowItem, idx: number) => {
                  return <input type='radio' name='slides' checked={false} id={`slide-${idx + 2}`} />;
                })}
            <ul class={style.carousel__slides}>
              {tableData !== undefined &&
                tableData
                  .filter((item, idx) => {
                    console.log('carousel_thumb #: ', idx);
                    return idx < 17;
                  })
                  .map((row: RowItem) => {
                    return (
                      <li class={style.carousel__slide}>
                        <figure>
                          <div>
                            <img src={`/gallery/${row.img}`} />
                          </div>
                          <figcaption>
                            {row.txt}
                            <span class={style.credit}>{row.ttl}</span>
                          </figcaption>
                        </figure>
                      </li>
                    );
                  })}
            </ul>
            <ul class={style.carousel__thumbnails}>
              {tableData !== undefined &&
                tableData
                  .filter((item, idx) => {
                    return idx < 17;
                  })
                  .map((row: RowItem, idx: number) => {
                    return (
                      <li>
                        <label for={`slide-${idx + 1}`}>
                          <img src={`/gallery/${row.img}`} />
                        </label>
                      </li>
                    );
                  })}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};
