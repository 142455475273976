import { h, FunctionalComponent } from 'preact';
import style from './blacklisteddomain.scss';
/*  Called from index.tsx  */

interface BlacklistedDomainProps {
  name: string;
}

export const BlacklistedDomain: FunctionalComponent<BlacklistedDomainProps> = ({ name }) => {
  const nname = name.replace(/\%20/g, ' ');
  return (
    <div class={style.blacklistedDomainDiv}>
      <div class={style.greeting}>
        Hello <span class={style.fgBlue}>{`${nname}`}</span>,
      </div>
      <div class={style.confirmation}>Thank you for registering for a Flottec account.</div>
      <div class={style.message}>
        We <span class={style.fgBlue}>confirm receipt</span> of your user account registration request and have added it to our processing list.
      </div>
      <div class={style.message}>
        Due to heavy workload, please allow up to <span class={style.fgBlue}>5</span> full business days for processing.
      </div>
      <div class={style.closing}>
        <div class={style.ty}>Thank you,</div>
        <div>Flottec Network Services,</div>
        <div>Main Corporate Office</div>
      </div>
    </div>
  );
};
