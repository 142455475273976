if (process.env.NODE_ENV === 'development') require('preact/debug');
import { h, render, FunctionalComponent, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks'; //, useEffect
import { Suspense, lazy } from 'preact/compat'; //, lazy
import style from './index.scss';
// import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
// import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
// import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/core/styles';
import { Switch, Route, useRoute } from 'wouter-preact';
import { Header } from './Components/Header/Header';
import { Loading } from './Components/Loading';
// import { get } from './utils/ajax';
// import bg01 from 'url:../public/img/bg01.webp';
// import bg02 from 'url:../public/img/bg02.webp';
import { Wrapper } from './Components/Wrapper/Wrapper';
import { Footer } from './Components/Footer/Footer';
import { Contact } from './Components/Contact/Contact';
import { Login } from './Components/Login/Login';
import { Register } from './Components/Register/Register';
import { VisibleModal } from './Components/types';
import { get } from './utils/ajax';
import { AuthorizeUser } from './Routes/AuthorizeUser/AuthorizeUser';
import { AppContext, UserData, AppState, Language } from './store/store'; //, Auth
import { BlacklistedDomain } from './Components/Register/Components/BlacklistedDomain';
import { UserRegistrationReceived } from './Components/Register/Components/UserRegistrationReceived';
import { EmailAlreadyRegistered } from './Components/Register/Components/EmailAlreadyRegistered';
// import { Gallery } from './Routes/Gallery/Gallery';
import { Galleria } from './Routes/Galleria/Galleria';

/**
 * Image imports
 */
import logoSmall from 'url:./img/logo_FT_xsvg.png';
import flagCanada from 'url:./img/flag-big-ca.png';
import flagChile from 'url:./img/flag-big-cl.png';
import flagMexico from 'url:./img/flag-big-mx.png';
import flagUSA from 'url:./img/flag-big-usa.png';

// import { Products } from './Routes/Products/Products';
// import { CMS } from './Routes/CMS/CMS';
// import { Network } from './Routes/Network/Network';
// import { RandD } from './Routes/RandD/RandD';
// import { MsdsSds } from './Routes/MsdsSds/MsdsSds';
// import { Company } from './Routes/Company/Company';
// import { Categories } from './Routes/Categories/Categories';
// import { Subcategories } from './Routes/Subcategories/Subcategories';
// import { Product } from './Routes/Product/Product';
import { Home } from './Routes/Home/Home';
const Products = lazy(() => import('./Routes/Products/Products'));
const CMS = lazy(() => import('./Routes/CMS/CMS'));
const Network = lazy(() => import('./Routes/Network/Network'));
const RandD = lazy(() => import('./Routes/RandD/RandD'));
const MsdsSds = lazy(() => import('./Routes/MsdsSds/MsdsSds'));
const Company = lazy(() => import('./Routes/Company/Company'));
const Categories = lazy(() => import('./Routes/Categories/Categories'));
const Subcategories = lazy(() => import('./Routes/Subcategories/Subcategories'));
const Product = lazy(() => import('./Routes/Product/Product'));
// document.documentElement.style.backgroundImage = `url(${bg01})`;
// const arrBG = [bg01, bg02];
// let currBG = 0;
// const swapBG = () => {
//   setTimeout(function () {
//     currBG = 0;
//     document.documentElement.style.backgroundImage = `url(${arrBG[currBG]})`;
//     const sldr = document.getElementById('slide');
//     if (sldr) sldr.style.backgroundImage = `url(${arrBG[currBG]})`;
//     swapBG();
//   }, 30000);
// };
// swapBG();

export type UserInfo = Exclude<keyof AppState, 'language'>;

const floLocs = {
  mx: 'https://flottec.mx',
  cl: 'https://flottec.cl',
  ca: 'https://flottec.ca',
  usa: '',
};
const refAll = ['flottec.mx', 'flottec.ca', 'flottec.cl'];

type CountryLocs = keyof typeof floLocs;

export const App: FunctionalComponent = () => {
  //Initially: authenticated SHOULD BE FALSE !!! ALSO: UNCOMMENT BELOW 5 LINES !!!
  const [language, setLanguage] = useState<Language>('en');
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [floRedirect, setFloRedirect] = useState<CountryLocs | null>(localStorage.getItem('floRedirect') as CountryLocs | null);
  const [visibleModal, setVisibleModal] = useState<VisibleModal | undefined>(undefined);

  const handleLogin = (data: AppState) => {
    setUserData(data.userData);
    setVisibleModal(undefined);
  };
  const handleLoginClose = () => {
    setVisibleModal(undefined);
    useRoute('/');
  };
  const handleRegistrationSuccess = () => {
    setVisibleModal(undefined);
  };

  /*  vvv  BEGIN: Initial Flag Choice - User can pre-set their preferred country  */
  const handleFlagClick = (dest: CountryLocs) => {
    const currDT = new Date();
    setFloRedirect(dest);
    localStorage.setItem('floRedirect', dest);
    localStorage.setItem('floLastVisit', `${+currDT}`);
    window.location.href = floLocs[dest];
  };
  //Handle case where user tweaks manually to different value (otherwise user is STUCK)
  if (!['usa', 'ca', 'mx', 'cl'].includes(floRedirect ?? '')) {
    setFloRedirect(null);
    localStorage.removeItem('floRedirect');
  }
  /* *********************************************************************** */
  useEffect(() => {
    /*  BEGIN: Initial Flag Choice - User can pre-set their preferred country  */
    get('/getScratchpadValue?key=rerouteDelaySecs').then((secs) => {
      const currDT = new Date();
      const intSecs = +secs.value;
      const rerouteSeconds = Number.isFinite(intSecs) ? intSecs * 1000 : 22000;

      const referrer = document.referrer;
      const flottecReferred = refAll.some((ref) => {
        return referrer.includes(ref);
      });
      if (flottecReferred) {
        setFloRedirect('usa');
        return;
      }

      const lastVisit = localStorage.getItem('floLastVisit') ?? currDT;
      if (floRedirect !== null) {
        // const visitDelta = +currDT - +lastVisit;
        // console.log(`visitDelta: ${visitDelta} - reRouteSecs: [${rerouteSeconds}]`);
        if (+currDT - +lastVisit > rerouteSeconds) {
          localStorage.setItem('floLastVisit', `${+currDT}`);
          window.location.href = floLocs[floRedirect];
        } else {
          setFloRedirect('usa');
        }
      }
    });
    /*  END: Initial Flag Choice - User can pre-set their preferred country  */
  }, []);
  /* *********************************************************************** */
  /*  ^^^  END: Initial Flag Choice - User can pre-set their preferred country  */

  /* *********************************************************************** */
  useEffect(() => {
    // console.log('### INDEX.TSX ### useEffect() ### [2]');
    // get('/getScratchpadValue?rerouteDelaySecs').then((s) => {
    //   const secs = Number.isFinite(s) ? s * 1000 : 22000;
    //   console.log(`index.tsx *** useEffect() *** rerouteSecs: [${secs}]`);
    //   setRerouteSeconds(secs);
    // });
    get<AppState>('/check-auth').then(({ userData, language }) => {
      setUserData(userData);
      setLanguage(language);
    });
  }, []);
  /* *********************************************************************** */

  return (
    <Fragment>
      {floRedirect === null && (
        <div class={style.triFlags}>
          <div class={style.flagsInner}>
            <div class={style.flagsBlurb}>
              <div class={style.flagsBlurbInner}>
                <img src={logoSmall} />
                <h2>Choose your Flottec:</h2>
              </div>
            </div>
            <div class={style.flagDiv}>
              <img class={style.flag} src={flagUSA} onClick={() => handleFlagClick('usa')} />
              <div class={style.flagCaption}>Flottec U.S.A.</div>
            </div>
            <div class={style.flagDiv}>
              <img class={style.flag} src={flagCanada} onClick={() => handleFlagClick('ca')} />
              <div class={style.flagCaption}>Flottec Canada (Chemiqa)</div>
            </div>
            <div class={style.flagDiv}>
              <img class={style.flag} src={flagMexico} onClick={() => handleFlagClick('mx')} />
              <div class={style.flagCaption}>Flottec Mexico</div>
            </div>
            <div class={style.flagDiv}>
              <img class={style.flag} src={flagChile} onClick={() => handleFlagClick('cl')} />
              <div class={style.flagCaption}>Flottec Chile</div>
            </div>
          </div>
          <div class={style.flagsBottom}>
            <p>
              Your selection will be remembered and you will be automatically redirected to your preferred country in future. Choose{' '}
              <span class={style.fgBlue} onClick={() => handleFlagClick('usa')}>
                Flottec USA
              </span>{' '}
              for no redirect.
            </p>
            <p class={style.fgGreen}>
              Se recordará su selección. Serás automáticamente redirigido a su país preferido en el futuro. Elija{' '}
              <span class={style.fgBlue} onClick={() => handleFlagClick('usa')}>
                Flottec USA
              </span>{' '}
              para no redireccionar.
            </p>
          </div>
        </div>
      )}
      {floRedirect === 'usa' && (
        <AppContext.Provider value={{ userData, language }}>
          <StylesProvider injectFirst>
            <CssBaseline />
            <Wrapper>
              <Header onLogin={() => setVisibleModal('login')} onLangChange={setLanguage} />
              <Switch>
                <Route path='/'>
                  <Home />
                </Route>
                <Route path='/rd'>
                  <Suspense fallback={<Loading />}>
                    <RandD />
                  </Suspense>
                </Route>
                <Route path='/sds'>
                  <Suspense fallback={<Loading />}>
                    <MsdsSds onLogin={() => setVisibleModal('login')} onRegister={() => setVisibleModal('register')} />
                  </Suspense>
                </Route>
                <Route path='/contact'>
                  <Suspense fallback={<Loading />}>
                    <Contact onClose={() => setVisibleModal(undefined)} />
                  </Suspense>
                </Route>
                <Route path='/network'>
                  <Suspense fallback={<Loading />}>
                    <Network />
                  </Suspense>
                </Route>
                <Route path='/company'>
                  <Suspense fallback={<Loading />}>
                    <Company />
                  </Suspense>
                </Route>
                <Route path='/gallery'>
                  <Galleria />
                </Route>
                <Route path='/products'>
                  <Suspense fallback={<Loading />}>
                    <Products />
                  </Suspense>
                </Route>
                <Route path='/accountProcessing/:email/name/:name'>
                  {(params) => <UserRegistrationReceived email={params.email} name={params.name} />}
                </Route>
                <Route path='/newAccountProcessing/:email/name/:name'>{(params) => <BlacklistedDomain name={params.name} />}</Route>
                <Route path='/newAccountRegistration/:error/id/:email'>
                  {(params) => <EmailAlreadyRegistered error={params.error} email={params.email} />}
                </Route>

                <Route path='/auth/:auth/bl/:bl/hash/:hash'>
                  {(params) => <AuthorizeUser auth={params.auth} bl={params.bl} hash={params.hash} />}
                </Route>
                <Route path='/categories/:id'>
                  {(params) => (
                    <Suspense fallback={<Loading />}>
                      <Categories catId={params.id} onLogin={() => setVisibleModal('login')} />
                    </Suspense>
                  )}
                </Route>
                <Route path='/categories/:cat_id/subcategories/:subcat_id'>
                  {(params) => (
                    <Suspense fallback={<Loading />}>
                      <Subcategories catId={params.cat_id} subCatId={params.subcat_id} onLogin={() => setVisibleModal('login')} />
                    </Suspense>
                  )}
                </Route>
                <Route path='/api/:api/product/:prodid'>
                  {(params) => (
                    <Suspense fallback={<Loading />}>
                      <Product api={params.api} catid={''} subcatid={''} prodid={params.prodid} onLogin={() => setVisibleModal('login')} />
                    </Suspense>
                  )}
                </Route>
                <Route path='/api/:api/cat/:catid/sub/:subcatid/product/:prodid'>
                  {(params) => (
                    <Suspense fallback={<Loading />}>
                      <Product
                        api={params.api}
                        catid={params.catid}
                        subcatid={params.subcatid}
                        prodid={params.prodid}
                        onLogin={() => setVisibleModal('login')}
                      />
                    </Suspense>
                  )}
                </Route>
                {userData?.authenticated && userData?.privLevel !== undefined && userData?.privLevel > 1 ? (
                  <Route path='/cms'>
                    <Suspense fallback={<Loading />}>
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <CMS onLangChange={setLanguage} />
                    </Suspense>
                  </Route>
                ) : (
                  <Route path='/'></Route>
                )}
              </Switch>
              <Footer onContact={() => setVisibleModal('contact')} />
            </Wrapper>
          </StylesProvider>
        </AppContext.Provider>
      )}

      {visibleModal === 'login' && <Login onSuccess={handleLogin} onDoCreateAccount={() => setVisibleModal('register')} onClose={handleLoginClose} />}
      {visibleModal === 'register' && (
        <Register onSuccess={handleRegistrationSuccess} onDoLogin={() => setVisibleModal('login')} onClose={() => setVisibleModal(undefined)} />
      )}
      {visibleModal === 'contact' && <Contact onClose={() => setVisibleModal(undefined)} />}
    </Fragment>
  );
};

render(<App />, document.body);
