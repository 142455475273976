import { h, FunctionalComponent, JSX } from 'preact';
import style from './autocompleter.scss';
import { get } from '../../utils/ajax';
import { useLocation } from 'wouter-preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { AppContext } from '../../store/store';

interface MysqlProductNames {
  id: number;
  cat_id: number;
  subcat_id: number;
  name_en: string;
}

export const Autocompleter: FunctionalComponent = () => {
  const $ = document.querySelector.bind(document);
  const $$ = document.querySelectorAll.bind(document);
  const [location, setLocation] = useLocation();
  const { language: lang } = useContext(AppContext);

  const [srxText, setSrxText] = useState('');
  const [srxResults, setSrxResults] = useState<MysqlProductNames[] | undefined>(undefined);
  const [lastCharWasArrow, setLastCharWasArrow] = useState(false);
  const [dnArrowIdx, setDnArrowIdx] = useState<number | undefined>(undefined);
  const handleSrxUserKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      setLastCharWasArrow(true);
      if (dnArrowIdx === undefined) {
        setDnArrowIdx(-1);
      } else {
        setDnArrowIdx(+dnArrowIdx + 1);
      }
    } else if (e.key === 'ArrowUp') {
      setLastCharWasArrow(true);
      if (dnArrowIdx !== undefined) {
        setDnArrowIdx(+dnArrowIdx - 1);
      }
    } else if (e.key === 'Escape') {
      setSrxText('');
      setDnArrowIdx(undefined);
      setSrxResults(undefined);
      setLastCharWasArrow(false);
    } else {
      setLastCharWasArrow(false);
      setDnArrowIdx(-1);
    }
    if (dnArrowIdx !== undefined && e.key === 'Enter' && lastCharWasArrow === true) {
      $$('#srxResultsDiv ul li').forEach((v, i) => {
        if (dnArrowIdx === i) {
          const tmpId = v.attributes[0];
          if (tmpId !== undefined) {
            const tId = tmpId.nodeValue;
            setSrxText('');
            setDnArrowIdx(undefined);
            setSrxResults(undefined);
            setLastCharWasArrow(false);
            setLocation(`/api/com/product/${tId}`);
          }
        }
      });
    }
  };
  const handleSrxUserInput = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setSrxText(val);

    get<MysqlProductNames[]>(`/getSrxAutocompleteProdsEn?title=${val}`).then((res) => {
      setSrxResults(res);
      if (dnArrowIdx === undefined) setDnArrowIdx(-1);
    });
  };
  const handleSrxLiClick = (prodId: number, catId: number, subCatId: number) => {
    setSrxText('');
    setDnArrowIdx(undefined);
    setSrxResults(undefined);
    setLastCharWasArrow(false);
    setLocation(`/api/sub/cat/${catId}/sub/${subCatId}/product/${prodId}`);
  };

  /* *********************************************************************** */
  useEffect(() => {
    if (dnArrowIdx !== undefined) {
      const allLi = Array.from(document.querySelectorAll('#srxResultsDiv ul li'));
      const dLi = $(`#srxResultsDiv ul li:nth-child(${+dnArrowIdx + 1})`);
      if (allLi !== null) {
        allLi.forEach((li) => {
          li.classList.remove('liHoverBg');
        });
      }
      if (dLi !== null) dLi.classList.add('liHoverBg');
    }
  }, [dnArrowIdx]);
  /* *********************************************************************** */

  const marginClass = location === '/' ? style.minLeftMargin : '';
  return (
    <div class={`${style.autocompleterDiv} ${marginClass}`}>
      <div class={style.searchDiv}>
        <div class={style.srxInputDiv}>
          <input
            id='srxInput'
            class={style.srxInputField}
            type='text'
            value={srxText}
            onKeyDown={handleSrxUserKeyUp}
            onKeyUp={handleSrxUserInput}
            placeholder={lang === 'en' ? 'type product name' : 'busca un product'}
          />
        </div>
        <div id='srxResultsDiv' class={style.srxResultsDiv}>
          <ul>
            {srxText.length > 0 &&
              srxResults !== undefined &&
              srxResults.length > 0 &&
              srxResults.map((r) => {
                return (
                  <li data-id={r.id} data-cat={r.cat_id} data-sub={r.subcat_id} onClick={() => handleSrxLiClick(r.id, r.cat_id, r.subcat_id)}>
                    {r.name_en}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};
