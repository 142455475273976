.autocompleterDiv {
  position: absolute;
  width: 15vw;
  min-height: 5vh;
  margin-left: 5vw;
  display: block;
}
.searchDiv {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 15vw;
  height: 1.8vw;
  padding: 0.25vw 0;
  font-size: calc(5px + 0.8vw);
  .srxInputDiv {
    .srxInputField {
      width: 100%;
      outline: unset;
      padding: 0.1vw 0.25vw;
      font-size: calc(5px + 0.9vw);
      font-family: 'Segoe UI light', 'Segoe UI', Arial, Helvetica, sans-serif;
      border: 1px solid rgba(200, 200, 200, 0.5);
      &:focus {
        border: 1px solid rgba(50, 100, 200, 0.5);
      }
      &::placeholder {
        color: #ccc;
        font-family: 'Segoe UI light', 'Segoe UI', Arial, Helvetica, sans-serif;
      }
    }
  }
  .srxResultsDiv {
    background: rgba(250, 250, 250, 0.9);
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        padding: 0 10px;
        font-size: 1.15vw;
        cursor: pointer;
        &:hover {
          background: #4774b354 !important;
        }
      }
    }
  }
}
.liHoverBg {
  background: #4774b354 !important;
}
.minLeftMargin {
  margin-left: 1vw !important;
}

@media (max-width: 925px) {
  .autocompleterDiv {
    display: none !important;
  }
}
